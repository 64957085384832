<template>
  <span class="pe-3">
    <button
      class="btn btn-secondary btn-sm"
      :title="$t(`incomingProducts.createInvoice`)"
      v-on:click="generate"
      v-if="value.generate_invoice !== false"
    >
      <BaseIcon name="invoice-add" />
    </button>
    <button
      class="btn btn-secondary btn-sm bg-success text-white"
      :title="$t(`invoiceDetails.donwloadInvoice`)"
      v-on:click="show"
      v-else-if="value.last_invoice !== null"
    >
      <BaseIcon name="invoice-download" />
    </button>
  </span>
  <InvoiceModal
    ref="modal"
    v-model="value.last_invoice"
    v-if="value.last_invoice > 0"
  />
</template>
<script>
import { defineAsyncComponent } from "vue";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));
const InvoiceModal = defineAsyncComponent(() =>
  import("../../modals/InvoiceModal.vue")
);

export default {
  name: "InvoiceDetails",
  components: { InvoiceModal, BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    show() {
      this.$refs.modal.showModal();
    },
    generate() {
      window.apps.callEvent("generate:invoice", this.id);
      this.$emit("generate");
    },
  },
  emits: ["update:modelValue", "change", "generate"],
};
</script>
